<template>
  <div>
    <v-row justify="center">
      <v-dialog
        v-model="isSettingFormActive"
        max-width="50%"
      >
        <v-card>
          <v-card-title>
            <span class="text-h5">Site Setting Details</span>
          </v-card-title>
          <v-card-text>
            <v-form
              ref="settingForm"
            >
              <v-row>
                <v-col
                  cols="12"
                  lg="6"
                >
                  <v-text-field
                    v-model="settingDTO.setting.id"
                    label="Setting ID"
                    hint="GUID"
                    readonly
                  ></v-text-field>
                </v-col>

                <v-col
                  cols="12"
                  lg="6"
                >
                  <v-switch
                    v-model="settingDTO.isActive"
                    color="primary"
                    :model-value="true"
                    label="Active Status"
                    :readonly="!isEdit"
                  >
                  </v-switch>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  lg="6"
                >
                  <v-text-field
                    v-model="settingDTO.setting.settingName"
                    label="Setting Name"
                    hint="ApprovalGracePeriod"
                    readonly
                    :rules="[required]"
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  lg="6"
                >
                  <v-textarea
                    v-model="settingDTO.setting.description"
                    label="Setting Description"
                    hint="Number of days staff has to approve or reject the purge, otherwise it will auto-approve"
                    :rules="[required]"
                    readonly
                  ></v-textarea>
                </v-col>
              </v-row>

              <v-row>
                <v-col
                  cols="12"
                  lg="6"
                >
                  <v-textarea
                    v-model="settingDTO.value"
                    label="Value"
                    hint="0"
                    :readonly="!isEdit"
                    :rules="[required]"
                  ></v-textarea>
                </v-col>
              </v-row>

              <div
                v-if="settingDTO.setting.settingName == 'ImportSettings'"
              >
                <v-row>
                  <v-col cols="4">
                    <v-text-field
                      v-model="value.Delimiter"
                      outline
                      label="Delimiter"
                      type="text"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="4">
                    <v-text-field
                      v-model="value.JacketId"
                      outline
                      label="Jacket Id"
                      type="text"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="4">
                    <v-text-field
                      v-model="value.BookingId"
                      outline
                      label="Booking Id"
                      type="text"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="4">
                    <v-text-field
                      v-model="value.FirstName"
                      outline
                      label="FirstName"
                      type="text"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="4">
                    <v-text-field
                      v-model="value.LastName"
                      outline
                      label="Last Name"
                      type="text"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="4">
                    <v-text-field
                      v-model="value.BirthDate"
                      outline
                      label="BirthDate"
                      type="text"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="4">
                    <v-text-field
                      v-model="value.Gender"
                      outline
                      label="Gender"
                      type="text"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="4">
                    <v-text-field
                      v-model="value.Race"
                      outline
                      label="Race"
                      type="text"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="4">
                    <v-text-field
                      v-model="value.SSN"
                      outline
                      label="SSN"
                      type="text"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="4">
                    <v-text-field
                      v-model="value.BookingDate"
                      outline
                      label="Booking Date"
                      type="text"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="4">
                    <v-text-field
                      v-model="value.ReleaseDate"
                      outline
                      label="Release Date"
                      type="text"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="4">
                    <v-text-field
                      v-model="value.Release"
                      outline
                      label="Release Text"
                      type="text"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="4">
                    <v-text-field
                      v-model="value.ImageName"
                      outline
                      label="Image Name"
                      type="text"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="4">
                    <v-text-field
                      v-model="value.ImageExtension"
                      outline
                      label="Image Extension"
                      type="text"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="4">
                    <v-text-field
                      v-model="value.FederalFlag"
                      outline
                      label="Federal Flag"
                      type="text"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="4">
                    <v-text-field
                      v-model="value.ICEFlag"
                      outline
                      label="ICE Flag"
                      type="text"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="4">
                    <v-text-field
                      v-model="value.DOCFlag"
                      outline
                      label="DOC Flag"
                      type="text"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="4">
                    <v-text-field
                      v-model="value.Classifications"
                      outline
                      label="Classifications List"
                      type="text"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="4">
                    <v-text-field
                      v-model="value.ClassificationsDelimiter"
                      outline
                      label="ClassificationsDelimiter"
                      type="text"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="4">
                    <v-text-field
                      v-model="value.LocationType"
                      outline
                      label="Location type (Delimited, Separate)"
                      type="text"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="4">
                    <v-text-field
                      v-model="value.LocationDelimiter"
                      outline
                      label="Location Delimiter"
                      type="text"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="4">
                    <v-text-field
                      v-model="value.Location"
                      outline
                      label="Location"
                      type="text"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="4">
                    <v-text-field
                      v-model="value.Pod"
                      outline
                      label="Location Pod"
                      type="text"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="4">
                    <v-text-field
                      v-model="value.Cell"
                      outline
                      label="Location Cell"
                      type="text"
                    ></v-text-field>
                  </v-col>
                  </v-row>
                  <v-row>
                  <v-col cols="4">
                    <v-text-field
                      v-model="value.Bed"
                      outline
                      label="Location Bed"
                      type="text"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="4">
                    <v-text-field
                      v-model="value.XMLNode"
                      outline
                      label="XMLNode (Inmate)"
                      type="text"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </div>

            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
              <v-row>
                <v-col class="text-right">
                  <v-btn
                    color="error darken-1"
                    type="reset"
                    text
                    @click="closeForm()"
                  >
                    Close
                  </v-btn>
                  <v-btn
                    color="primary darken-1"
                    text
                    @click="submitForm(settingDTO)"
                  >
                    Save
                  </v-btn>
                </v-col>
              </v-row>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import store from '@/store'
import { required } from '@core/utils/validation'
import { mdiClose } from '@mdi/js'
import { ref, onUnmounted } from '@vue/composition-api'
import siteService from '@/services/SiteService'
import useSiteList from '@/store/useSiteService'

export default {
  props: {
    isSettingFormActive: {
      type: Boolean,
      required: true,
    },
    isEdit: {
      type: Boolean,
      required: true,
    },
    settingDTO: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      value:{
        Delimiter: ",",
        JacketId: 0,
        BookingId: null,
        FirstName: 3,
        LastName: 2,
        BirthDate: 12,
        Gender: 11,
        Race: 13,
        SSN: 6,
        BookingDate: 7,
        ReleaseDate: 9,
        Release: 5,
        ImageName: 20,
        ImageExtension: null,
        FederalFlag: 22,
        ICEFlag: 23,
        DOCFlag: 24,
        Classifications: null,
        ClassificationsDelimiter: "*",
        LocationType: "Delimited",
        LocationDelimiter: "*",
        Location: 5,
        Pod: 1,
        Cell: 2,
        Bed: null,
        XMLNode: null
      }
    }
  },
  mounted() {
    if (this.settingDTO.id === this.settingDTO.setting.id) {
      this.settingDTO.value = this.settingDTO.setting.defaultValue
    }

    if (this.settingDTO.setting.settingName == 'ImportSettings'){
      if(this.value != JSON.parse(this.settingDTO.value)){
        this.value = JSON.parse(this.settingDTO.value)
      }
    }
  },
  setup(props, { emit }) {
    const settingForm = ref(null)
    const { postSiteSetting } = useSiteList()

    const validate = settingDTO => {
      if (settingForm.value.validate(settingDTO)) {
        return true
      }

      return false
    }

    const refreshParent = () => {
      emit('refetch-data')
    }

    const closeForm = () => {
      emit('update-dialog')
    }

    const SITE_APP = 'app-site'

    // Register module
    if (!store.hasModule(SITE_APP)) store.registerModule(SITE_APP, siteService)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(SITE_APP)) store.unregisterModule(SITE_APP)
    })

    return {
      settingForm,
      validate,
      closeForm,
      refreshParent,

      postSiteSetting,

      required,

      icons: {
        mdiClose,
      },
    }
  },
  methods: {
    submitForm(settingDTO) {
      //console.log(settingDTO)
      if (this.settingDTO.setting.settingName == 'ImportSettings'){
        this.settingDTO.value = JSON.stringify(this.value, null, 2)
      }
      console.log(this.settingDTO.value)

      if (this.validate(settingDTO)) {
        this.postSiteSetting(settingDTO)
        this.closeForm()
        this.refreshParent()
      } else {
        //console.log('Not Valid')
      }
    },
  },
}
</script>
