<template>
  <div id="site-setting-list">
    <!-- dialog -->
    <delete-confirmation
      v-model="isDeleteDialogActive"
      :selected-item="selectedItem"
      @delete-confirmation="submitDelete"
      @refetch-data="fetchSiteSettings"
    ></delete-confirmation>

    <site-setting-add-edit
      v-if="isSettingFormActive"
      v-model="isSettingFormActive"
      :is-setting-form-active="isSettingFormActive"
      :is-edit="isEdit"
      :setting-d-t-o="selectedItem"
      @refetch-data="fetchSiteSettings"
      @update-dialog="disableSiteSetting"
    >
    </site-setting-add-edit>

    <v-card>
      <v-card-text class="d-flex align-center flex-wrap pb-0">
        <!-- search -->
        <v-text-field
          v-model="searchQuery"
          placeholder="Search"
          outlined
          hide-details
          dense
          class="site-search me-3 mb-4"
        >
        </v-text-field>
      </v-card-text>

      <!-- table -->
      <v-data-table
        key="setting.id"
        :headers="settingTableColumns"
        :items="siteSettingListTable"
        :loading="loading"
        :search="searchQuery"
        group-by="setting.settingGroup.settingGroupName"
        :group-desc="false"
        sort-by="setting.settingName"
        :sort-desc="false"
      >
        <template #[`item.setting.settingName`]="{item}">
          <td
            class="text-xs-left color-corner"
            :class="item.isActive.toString()"
          >
            {{ item.setting.settingName }}
          </td>
        </template>
        <!-- actions -->
        <template #[`item.actions`]="{item}">
          <v-menu
            bottom
            left
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                v-bind="attrs"
                v-on="on"
                @click="selectedItem = item"
              >
                <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item link>
                <v-btn
                  text
                  x-small
                  @click="isEdit = false, updateDialog()"
                >
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiFileDocumentOutline }}
                  </v-icon>
                  <span>Details</span>
                </v-btn>
              </v-list-item>

              <v-list-item link>
                <v-btn
                  text
                  x-small
                  @click="isEdit = true, updateDialog()"
                >
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiAccountEditOutline }}
                  </v-icon>
                  <span>Edit</span>
                </v-btn>
              </v-list-item>

              <v-list-item link>
                <v-btn
                  text
                  x-small
                  @click="disableSiteSetting(item)"
                >
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiAccountLockOutline }}
                  </v-icon>
                  <span>{{ item.isActive ? 'Disable' : 'Activate' }}</span>
                </v-btn>
              </v-list-item>

              <v-list-item link>
                <v-btn
                  text
                  x-small
                  @click="isDeleteDialogActive = !isDeleteDialogActive"
                >
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiDeleteOutline }}
                  </v-icon>
                  <span>Delete</span>
                </v-btn>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { onUnmounted, ref } from '@vue/composition-api'
import {
  mdiAccountOutline,
  mdiDeleteOutline,
  mdiDotsVertical,
  mdiExportVariant,
  mdiFileDocumentOutline,
  mdiPlus,
  mdiAccountEditOutline,
  mdiAccountLockOutline,
} from '@mdi/js'
import store from '@/store'
import siteService from '@/services/SiteService'
import DeleteConfirmation from '@/components/validation/DeleteConfirmation.vue'
import useSiteService from '@/store/useSiteService'
import SiteSettingAddEdit from './SiteSettingAddEdit.vue'

export default {
  components: { DeleteConfirmation, SiteSettingAddEdit },
  mounted() {
    const site = localStorage.getItem('useSiteId')
    this.fetchSiteSettings(site)
  },
  setup() {
    const SITE_APP = 'app-site'

    // Register module
    if (!store.hasModule(SITE_APP)) store.registerModule(SITE_APP, siteService)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(SITE_APP)) store.unregisterModule(SITE_APP)
    })

    const { siteSettingListTable, settingTableColumns, loading, options, fetchSiteSettings } =
      useSiteService()

    const isSettingFormActive = ref(false)
    const isDeleteDialogActive = ref(false)
    const isEdit = ref(false)
    const selectedItem = ref()
    const searchQuery = ref()

    return {
      siteSettingListTable,
      settingTableColumns,
      loading,
      options,
      selectedItem,
      fetchSiteSettings,
      searchQuery,

      isDeleteDialogActive,
      isSettingFormActive,
      isEdit,
      icons: {
        mdiAccountOutline,
        mdiDeleteOutline,
        mdiDotsVertical,
        mdiExportVariant,
        mdiFileDocumentOutline,
        mdiPlus,
        mdiAccountEditOutline,
        mdiAccountLockOutline,
      },
    }
  },
  methods: {
    disableSiteSetting(item) {
      // console.log('disableSiteSetting', item)
    },
    submitDelete(siteDTO) {
      this.deleteSite(siteDTO)
    },
    updateDialog() {
      this.isSettingFormActive = !this.isSettingFormActive
      if (!this.isSettingFormActive) {
        this.selectedItem = ref()
      }

      // console.log(this.isSettingFormActive)
    },
  },
}
</script>

<style lang="scss">
.color-corner {
  position: relative;
  &:before {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    content: '';
    width: 0;
    height: 0;
    border-right: 10px solid transparent;
    border-bottom: 10px solid transparent;
  }
}
.true:before {
  border-left: 10px solid #ff8230;
  border-top: 10px solid #ff8230;
}
</style>
