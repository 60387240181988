import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"site-setting-list"}},[_c('delete-confirmation',{attrs:{"selected-item":_vm.selectedItem},on:{"delete-confirmation":_vm.submitDelete,"refetch-data":_vm.fetchSiteSettings},model:{value:(_vm.isDeleteDialogActive),callback:function ($$v) {_vm.isDeleteDialogActive=$$v},expression:"isDeleteDialogActive"}}),(_vm.isSettingFormActive)?_c('site-setting-add-edit',{attrs:{"is-setting-form-active":_vm.isSettingFormActive,"is-edit":_vm.isEdit,"setting-d-t-o":_vm.selectedItem},on:{"refetch-data":_vm.fetchSiteSettings,"update-dialog":_vm.disableSiteSetting},model:{value:(_vm.isSettingFormActive),callback:function ($$v) {_vm.isSettingFormActive=$$v},expression:"isSettingFormActive"}}):_vm._e(),_c(VCard,[_c(VCardText,{staticClass:"d-flex align-center flex-wrap pb-0"},[_c(VTextField,{staticClass:"site-search me-3 mb-4",attrs:{"placeholder":"Search","outlined":"","hide-details":"","dense":""},model:{value:(_vm.searchQuery),callback:function ($$v) {_vm.searchQuery=$$v},expression:"searchQuery"}})],1),_c(VDataTable,{key:"setting.id",attrs:{"headers":_vm.settingTableColumns,"items":_vm.siteSettingListTable,"loading":_vm.loading,"search":_vm.searchQuery,"group-by":"setting.settingGroup.settingGroupName","group-desc":false,"sort-by":"setting.settingName","sort-desc":false},scopedSlots:_vm._u([{key:"item.setting.settingName",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"text-xs-left color-corner",class:item.isActive.toString()},[_vm._v(" "+_vm._s(item.setting.settingName)+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c(VMenu,{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){_vm.selectedItem = item}}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v(_vm._s(_vm.icons.mdiDotsVertical))])],1)]}}],null,true)},[_c(VList,[_c(VListItem,{attrs:{"link":""}},[_c(VBtn,{attrs:{"text":"","x-small":""},on:{"click":function($event){_vm.isEdit = false, _vm.updateDialog()}}},[_c(VIcon,{staticClass:"me-2",attrs:{"size":"20"}},[_vm._v(" "+_vm._s(_vm.icons.mdiFileDocumentOutline)+" ")]),_c('span',[_vm._v("Details")])],1)],1),_c(VListItem,{attrs:{"link":""}},[_c(VBtn,{attrs:{"text":"","x-small":""},on:{"click":function($event){_vm.isEdit = true, _vm.updateDialog()}}},[_c(VIcon,{staticClass:"me-2",attrs:{"size":"20"}},[_vm._v(" "+_vm._s(_vm.icons.mdiAccountEditOutline)+" ")]),_c('span',[_vm._v("Edit")])],1)],1),_c(VListItem,{attrs:{"link":""}},[_c(VBtn,{attrs:{"text":"","x-small":""},on:{"click":function($event){return _vm.disableSiteSetting(item)}}},[_c(VIcon,{staticClass:"me-2",attrs:{"size":"20"}},[_vm._v(" "+_vm._s(_vm.icons.mdiAccountLockOutline)+" ")]),_c('span',[_vm._v(_vm._s(item.isActive ? 'Disable' : 'Activate'))])],1)],1),_c(VListItem,{attrs:{"link":""}},[_c(VBtn,{attrs:{"text":"","x-small":""},on:{"click":function($event){_vm.isDeleteDialogActive = !_vm.isDeleteDialogActive}}},[_c(VIcon,{staticClass:"me-2",attrs:{"size":"20"}},[_vm._v(" "+_vm._s(_vm.icons.mdiDeleteOutline)+" ")]),_c('span',[_vm._v("Delete")])],1)],1)],1)],1)]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }